import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import SetObjectiveModal from './components/SetObjectiveModal'
import List from './pages/company-list.jsx';
import Detail from './pages/Detail'
import CompanyEdit from './pages/company-edit'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
const App = () => {
    return (
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/list" render={()=>{
              return ('visited' in localStorage)
              ? <List />
              : <SetObjectiveModal/>}} exact={true} />
            <Route path="/detail/:name" component={Detail}/>
            <Route path="/edit/:name" component={CompanyEdit}/>
            <Route path="/help" component={SetObjectiveModal}/>
            <Route path="/" render={() => <Redirect to="/list" />} exact={true} />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    );
};
export default App;