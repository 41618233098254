const registMessage = {
    1:"千里の道も一歩から！",
    2:"ちりも積もれば山となる！",
    3:"軌道に乗ってきてるね！",
    4:"不吉な数字！もう一社登録しよう！",
    5:"三日坊主卒業！",
    6:"もう片手じゃ数えられないね！",
    7:"ラッキーセブン！",
    8:"関ジャニ∞もびっくり！",
    9:"ドラクエもⅨが一番面白いもんね！",
    10:"一流の就活生になったね",
    20:"いい会社は見つかったかい？",
    30:"二進数ならまだ片手",
    40:"頑張りすぎてない？",
    50:"自分にご褒美上げよう！",
    100:""
}
export default registMessage;