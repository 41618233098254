import React from 'react';

import './PromoteRegist.css';

const PromoteRegist = () => {
  return (
    <div className="container">
      <p>右下のボタンから企業を登録してみよう！</p>
    </div>
  );
};
export default PromoteRegist;